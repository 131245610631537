<template>
  <div class="content_body SavingCard" v-loading="loading">
    <div class="nav_header">
      <el-row>
        <el-col :span="20">
          <el-form :inline="true" size="small" @keyup.enter.native="handleSearch">
            <el-form-item label="储值卡名称">
              <el-input @clear="handleSearch" v-model="name" placeholder="输入储值卡名称或别名搜索" clearable></el-input>
            </el-form-item>
            <el-form-item label="储值卡分类">
              <el-select @change="handleSearch" @clear="handleSearch" v-model="savingCardCategoryID" placeholder="选择储值卡分类" clearable filterable>
                <el-option v-for="item in category" :key="item.ID" :label="item.Name" :value="item.ID"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="是否上架">
              <el-select @change="handleSearch" @clear="handleSearch" v-model="isAllowSell" placeholder="请选择是否上架" clearable>
                <el-option label="是" :value="true"></el-option>
                <el-option label="否" :value="false"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="handleSearch" v-prevent-click>搜索</el-button>
            </el-form-item>
          </el-form>
        </el-col>
        <el-col :span="4" class="text_right">
          <el-button v-if="isSavingCardNew" type="primary" size="small" @click="showAddDialog">新增</el-button>
          <el-button type="primary" plain size="small" @click="showAddDialog" v-if="false">导入</el-button>
        </el-col>
      </el-row>
    </div>

    <div class="martp_10">
      <el-table size="small" :data="tableData">
        <el-table-column prop="Name" label="储值卡名称"></el-table-column>
        <el-table-column prop="Alias" label="储值卡别名"></el-table-column>
        <el-table-column prop="SavingCardCategoryName" label="储值卡分类"></el-table-column>
        <el-table-column prop="Price" label="储值卡金额">
          <template slot-scope="scope">￥{{ scope.row.Price | NumFormat }}</template>
        </el-table-column>
        <el-table-column prop="LargessPrice" label="储值卡赠送金额">
          <template slot-scope="scope">￥{{ scope.row.LargessPrice | NumFormat }}</template>
        </el-table-column>
        <el-table-column prop="ValidDayName" label="有效期"></el-table-column>
        <el-table-column prop="IsAllowSell" label="是否上架" :formatter="formatStatus"></el-table-column>
        <el-table-column label="操作" width="145">
          <template slot-scope="scope">
            <el-button v-if="isSavingCardEdit" type="primary" size="small" @click="showEditDialog(scope.row,'edit')">编辑</el-button>
            <el-button type="primary" size="small" @click="showEditDialog(scope.row,'show')">详情</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="text_right pad_15">
        <el-pagination background v-if="paginations.total > 0" @current-change="handleCurrentChange" :current-page.sync="paginations.page" :page-size="paginations.page_size" :layout="paginations.layout" :total="paginations.total"></el-pagination>
      </div>
    </div>
    <!--新增，编辑弹窗-->
    <el-dialog :title="isAdd ? '新增储值卡' : '编辑储值卡' " :visible.sync="dialogVisible" :close-on-click-modal="false" width="980px">
      <div>
        <el-tabs v-model="activeName">
          <el-tab-pane label="基本信息" name="1">
            <el-form :model="ruleForm" :rules="rules" ref="ruleForm" size="small" label-width="120px" class="demo-ruleForm">
              <el-row>
                <el-col :span="12">
                  <el-form-item label="储值卡名称" prop="Name">
                    <el-input v-model="ruleForm.Name"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="储值卡别名">
                    <el-input v-model="ruleForm.Alias"></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="12">
                  <el-form-item label="储值卡分类" prop="SavingCardCategoryID">
                    <el-select clearable v-model="ruleForm.SavingCardCategoryID" placeholder="请选择储值卡分类" style="position: absolute">
                      <el-option v-for="item in category" :key="item.ID" :label="item.Name" :value="item.ID"></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="售卖日期" prop="saleDate">
                    <el-date-picker clearable v-model="ruleForm.saleDate" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" value-format="yyyy-MM-dd" unlink-panels></el-date-picker>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="24">
                  <el-form-item label="金额/赠送金额" prop="Price">
                    <el-input style="display: inline-block; width: 100px" v-model="ruleForm.Price" type="number" placeholder="金额" v-positiveNumber :disabled="ruleForm.IsUseRechargeRules"></el-input>
                    <el-input class="marlt_10" style="display: inline-block; width: 100px" v-model="ruleForm.LargessPrice" type="number" placeholder="赠送金额" v-positiveNumber :disabled="ruleForm.IsUseRechargeRules"></el-input>
                    <el-checkbox class="marlt_15" v-model="ruleForm.IsSettingLargess">单独设置赠额可用范围</el-checkbox>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="24">
                  <el-form-item>
                    <el-checkbox class="" v-model="ruleForm.IsUseRechargeRules" @change="changeIsUseRechargeRules">使用阶梯价格</el-checkbox>
                    <el-checkbox class="marlt_15" v-model="ruleForm.IsModifyPrice">是否可修改本金</el-checkbox>
                    <el-checkbox class="marlt_15" v-model="ruleForm.IsModifyLargessPrice">是否可修改赠金</el-checkbox>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="12">
                  <el-form-item label="本金使用范围">
                    <el-radio-group v-model="ruleForm.IsGoodsRange">
                      <el-radio :label="true">所有非储值类商品</el-radio>
                      <el-radio :label="false">指定商品</el-radio>
                    </el-radio-group>
                  </el-form-item>
                </el-col>
                <el-col :span="12" v-if="ruleForm.IsSettingLargess">
                  <el-form-item label="赠额使用范围">
                    <el-radio-group v-model="ruleForm.IsLargessGoodsRange">
                      <el-radio :label="true">所有非储值类商品</el-radio>
                      <el-radio :label="false">指定商品</el-radio>
                    </el-radio-group>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-col :span="12">
                <el-form-item label="是否销售抵扣" prop="IsAllowSaleDeduct">
                  <el-radio-group v-model="ruleForm.IsAllowSaleDeduct">
                    <el-radio :label="true">是</el-radio>
                    <el-radio :label="false">否</el-radio>
                  </el-radio-group>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="是否允许消耗" prop="IsAllowConsumeDeduct">
                  <el-radio-group v-model="ruleForm.IsAllowConsumeDeduct">
                    <el-radio :label="true">是</el-radio>
                    <el-radio :label="false">否</el-radio>
                  </el-radio-group>
                </el-form-item>
              </el-col>
              <el-col :span="16">
                <el-form-item label="卡有效期" prop="ValidType">
                  <div>
                    <el-radio-group v-model="ruleForm.ValidType">
                      <el-radio :label="3">永久有效</el-radio>
                      <el-radio :label="1">销售后生成</el-radio>
                      <el-radio :label="2">消耗后生成</el-radio>
                    </el-radio-group>
                    <span v-if="ruleForm.ValidType != 3">
                      <el-input class="radio_input" v-model="ruleForm.ValidDay" placeholder="天" type="number" v-enter-number v-enterNumber2 min="0">
                        <template slot="append">天</template>
                      </el-input>
                    </span>
                  </div>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="是否上架" prop="IsAllowSell">
                  <el-radio-group v-model="ruleForm.IsAllowSell">
                    <el-radio :label="true">是</el-radio>
                    <el-radio :label="false">否</el-radio>
                  </el-radio-group>
                </el-form-item>
              </el-col>
               <el-col :span="8">
                <el-form-item label="是否返佣卡" prop="IsAllowSell">
                  <el-radio-group v-model="ruleForm.IsReturnedCommission">
                    <el-radio :label="true">是</el-radio>
                    <el-radio :label="false">否</el-radio>
                  </el-radio-group>
                </el-form-item>
              </el-col>
              <el-col :span="24">
                <el-form-item label="备注信息">
                  <el-input type="textarea" :rows="4" placeholder="请输入备注信息" v-model="ruleForm.Remark"></el-input>
                </el-form-item>
              </el-col>
            </el-form>
          </el-tab-pane>

          <el-tab-pane label="详细信息" name="7">
            <div class="dis_flex flex_dir_row">
              <goodsDetail ref="goodsDetail" :Memo="ruleForm.Memo" :ImageList="ruleForm.ImageList" :OriginalText="ruleForm.OriginalText" :Price="ruleForm.Price" :Name="ruleForm.Name" :isShowOriginal="false" GoodsType="储值卡" @Memo_change="Memo_change" @ImageListChange="ImageListChange">

                <el-row slot="info">
                  <el-col :span="24" class="padlt_10 padrt_10" style="height:40px;line-height:40px">商品信息</el-col>
                  <el-col :span="24" class="back_f8 pad_10">
                    <el-col :span="24" class="martp_5">有效期:
                      <span v-if="ruleForm.ValidType == 1">购买后 {{ruleForm.ValidDay}} 天内有效</span>
                      <span v-if="ruleForm.ValidType == 2">第一次使用后 {{ruleForm.ValidDay}} 天内有效</span>
                      <span v-if="ruleForm.ValidType == 3">永久有效</span>
                    </el-col>
                    <el-col :span="24" class="martp_5">适用门店: 适用共 {{ruleForm.ApplyNum}} 家门店 <span style="color:#FF8D66">查看门店</span></el-col>
                    <el-col :span="24" class="martp_5">本金适用商品: 适用共 {{goodsRangeList.length}} 个商品 <span style="color:#FF8D66">查看详情</span></el-col>
                    <el-col :span="24" class="martp_5">赠金适用商品: 适用共 {{goodsRangeLargessList.length}} 个商品 <span style="color:#FF8D66">查看详情</span></el-col>
                  </el-col>
                </el-row>
              </goodsDetail>
            </div>

          </el-tab-pane>

          <el-tab-pane label="设置阶梯价格" name="6" v-if="ruleForm.IsUseRechargeRules">
            <el-form :model="ruleForm" :rules="rules" ref="rechargeRulesRuleForm" size="small" label-width="120px" class="demo-ruleForm">
              <el-col class="dis_flex flex_dir_row" :span="24" v-for="(item,index) in ruleForm.SavingCardRechargeRules" :key="index">
                <el-form-item label="金额/赠送金额" :prop="'SavingCardRechargeRules.' + index + '.Price'" :rules="rules.RechargePrice">
                  <el-input style="display: inline-block; width: 100px" placeholder="金额" v-model="item.Price" v-positiveNumber size="small"></el-input>
                </el-form-item>
                <el-form-item label-width="20px" :prop="'SavingCardRechargeRules.' + index + '.LargessPrice'" :rules="rules.RechargeLargessPrice">
                  <el-input class="marlt_10 marrt_20" style="display: inline-block; width: 100px" placeholder="赠送金额" v-model="item.LargessPrice" v-positiveNumber size="small"></el-input>
                </el-form-item>
                <el-form-item label-width="0">
                  <el-button type="danger" icon="el-icon-close" circle size="mini" @click="removeTieredPriceClick(index)" v-if="(index + 1) != 1"></el-button>
                  <el-button type="primary" icon="el-icon-plus" circle size="mini" @click="addTieredPriceClick" v-if="(index + 1) == ruleForm.SavingCardRechargeRules.length"></el-button>
                </el-form-item>
              </el-col>

            </el-form>
          </el-tab-pane>

          <el-tab-pane label="本金适用范围" name="4" v-if="!ruleForm.IsGoodsRange">
            <el-row :gutter="20" class="marbm_10">
              <el-col :span="6">
                <el-input placeholder="输入商品名称/分类名称搜索" size="small" v-model="filterGoodsRangeProjectName" clearable></el-input>
              </el-col>
              <el-col :span="12">
                <el-button type="primary" size="small" @click="addProjectDialog(1)">添加本金适用范围</el-button>
              </el-col>
            </el-row>
            <el-table size="small" max-height="400" :data="
                goodsRangeList.filter(
                  (data) =>
                    !filterGoodsRangeProjectName ||
                    data.Name.toLowerCase().includes(
                      filterGoodsRangeProjectName.toLowerCase()
                    )
                )
              ">
              <el-table-column prop="Name" label="商品名称/分类名称" sortable>
                <template slot-scope="scope">
                  <span>{{ scope.row.Name }}</span>
                  <el-tag type="info" size="mini" v-if="scope.row.IsGoods == 0">分类</el-tag>
                </template>
              </el-table-column>
              <el-table-column prop="cardType" label="商品类型" sortable></el-table-column>
              <el-table-column prop="Price" label="销售价格" sortable></el-table-column>
              <el-table-column prop="DiscountPrice" label="优惠规则">
                <template slot-scope="scope">
                  <span v-if="scope.row.IsGoods == 0">{{ scope.row.DiscountPrice }}折</span>
                  <span v-if="scope.row.IsGoods == 1 && scope.row.PriceType == 1">{{ scope.row.DiscountPrice }}折</span>
                  <span v-if="scope.row.IsGoods == 1 && scope.row.PriceType == 2">¥{{ scope.row.DiscountPrice }}</span>
                </template>
              </el-table-column>
            </el-table>
          </el-tab-pane>
          <el-tab-pane label="赠额使用范围" name="5" v-if="ruleForm.IsSettingLargess && !ruleForm.IsLargessGoodsRange">
            <el-row :gutter="20" class="marbm_10">
              <el-col :span="6">
                <el-input placeholder="输入商品名称/分类名称搜索" size="small" v-model="filterGoodsRangeLargessProjectName" clearable></el-input>
              </el-col>
              <el-col :span="12">
                <el-button type="primary" size="small" @click="addProjectDialog(2)">添加赠额使用范围</el-button>
              </el-col>
            </el-row>
            <el-table max-height="400" size="small" :data="
                goodsRangeLargessList.filter(
                  (data) =>
                    !filterGoodsRangeLargessProjectName ||
                    data.Name.toLowerCase().includes(
                      filterGoodsRangeLargessProjectName.toLowerCase()
                    )
                )
              ">
              <el-table-column prop="Name" label="商品分类名称/商品名称" sortable>
                <template slot-scope="scope">
                  <span>{{ scope.row.Name }}</span>
                  <el-tag type="info" size="mini" v-if="scope.row.IsGoods == 0">分类</el-tag>
                </template>
              </el-table-column>
              <el-table-column prop="cardType" label="商品类型" sortable></el-table-column>
              <el-table-column prop="Price" label="销售价格" sortable></el-table-column>
              <el-table-column prop="DiscountPrice" label="优惠规则">
                <template slot-scope="scope">
                  <span v-if="scope.row.IsGoods == 0">{{ scope.row.DiscountPrice }}折</span>
                  <span v-if="scope.row.IsGoods == 1 && scope.row.PriceType == 1">{{ scope.row.DiscountPrice }}折</span>
                  <span v-if="scope.row.IsGoods == 1 && scope.row.PriceType == 2">¥{{ scope.row.DiscountPrice }}</span>
                </template>
              </el-table-column>
            </el-table>
          </el-tab-pane>
          <el-tab-pane label="销售范围" name="2">
            <div slot="label">
              销售范围
              <el-popover placement="top-start" width="200" trigger="hover">
                <p>适用于同级所有节点，则只需勾选父节点。</p>
                <p>比如：适用于所有节点，只需勾选“顶级/第一个”节点。</p>
                <el-button type="text" style="color: #dcdfe6" icon="el-icon-info" slot="reference"></el-button>
              </el-popover>
            </div>
            <el-scrollbar class="el-scrollbar_height">
              <el-tree ref="treeSale" :expand-on-click-node="false" :check-on-click-node="true" :check-strictly="true" :data="saleEntityList" show-checkbox node-key="ID" :default-checked-keys="defaultCheckedKeysSale" :default-expanded-keys="defaultExpandedKeysSale" :props="defaultProps"></el-tree>
            </el-scrollbar>
          </el-tab-pane>
          <el-tab-pane label="消耗范围" name="3">
            <div slot="label">
              消耗范围
              <el-popover placement="top-start" width="200" trigger="hover">
                <p>适用于同级所有节点，则只需勾选父节点。</p>
                <p>比如：适用于所有节点，只需勾选“顶级/第一个”节点。</p>
                <el-button type="text" style="color: #dcdfe6" icon="el-icon-info" slot="reference"></el-button>
              </el-popover>
            </div>
            <el-scrollbar class="el-scrollbar_height">
              <el-tree ref="treeConsume" :expand-on-click-node="false" :check-on-click-node="true" :check-strictly="true" :data="consumeEntityList" show-checkbox node-key="ID" :default-checked-keys="defaultCheckedKeysConsume" :default-expanded-keys="defaultExpandedKeysConsume" :props="defaultProps"></el-tree>
            </el-scrollbar>
          </el-tab-pane>
        </el-tabs>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button size="small" @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" size="small" @click="addSubmit" :loading="modalLoading">保 存</el-button>
      </div>
    </el-dialog>
    <!-- 查看弹出层 -->
    <el-dialog title="储值卡详情" :visible.sync="showDialogVisible" width="980px">
      <div>
        <el-tabs v-model="activeName">
          <el-tab-pane label="基本信息" name="1">
            <el-form size="small" label-width="150px" class="demo-ruleForm savingShow">
              <el-row>
                <el-col :span="12">
                  <el-form-item label="储值卡名称：">
                    <span>{{ruleForm.Name}}</span>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="储值卡别名：">
                    <span>{{ruleForm.Alias}}</span>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="12">
                  <el-form-item label="储值卡分类：">
                    <span>{{SavingCardCategory}}</span>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="售卖日期：">
                    <span>{{saleDate}}</span>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="24">
                  <el-form-item label="金额/赠送金额：">
                    <span class="marrt_10">{{ruleForm.Price}}</span>
                    <span class="marrt_10">{{ruleForm.LargessPrice}}</span>
                    <span v-if="ruleForm.IsSettingLargess">单独设置赠额可用范围</span>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="24">
                  <el-form-item>
                    <span class="marrt_10" v-if="ruleForm.IsUseRechargeRules">使用阶梯价格</span>
                    <span class="marrt_10" v-else>不用阶梯价格</span>
                    <span class="marrt_10" v-if="ruleForm.IsModifyPrice">可修改本金</span>
                    <span class="marrt_10" v-else>不可修改本金</span>
                    <span v-if="ruleForm.IsModifyLargessPrice">可修改赠金</span>
                    <span v-else>不可修改赠金</span>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="12">
                  <el-form-item label="本金使用范围：">
                    <span v-if="ruleForm.IsGoodsRange">所有非储值类商品</span>
                    <span v-else>指定商品</span>
                  </el-form-item>
                </el-col>
                <el-col :span="12" v-if="ruleForm.IsSettingLargess">
                  <el-form-item label="赠额使用范围：">
                    <span v-if="ruleForm.IsLargessGoodsRange">所有非储值类商品</span>
                    <span v-else>指定商品</span>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-col :span="12">
                <el-form-item label="是否销售抵扣：">
                  <span v-if="ruleForm.IsAllowSaleDeduct">是</span>
                  <span v-else>否</span>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="是否允许消耗：">
                  <span v-if="ruleForm.IsAllowConsumeDeduct">是</span>
                  <span v-else>否</span>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="卡有效期：">
                  <span v-if="ruleForm.ValidType==3">永久有效</span>
                  <span v-else-if="ruleForm.ValidType==1">销售后生成{{ruleForm.ValidDay}}天</span>
                  <span v-else>消耗后生成{{ruleForm.ValidDay}}天</span>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="是否上架：">
                  <span v-if="ruleForm.IsAllowSell">是</span>
                  <span v-else>否</span>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="是否返佣卡：">
                  <span v-if="ruleForm.IsReturnedCommission">是</span>
                  <span v-else>否</span>
                </el-form-item>
              </el-col>
              <el-col :span="24">
                <el-form-item label="备注信息：">
                  <span>{{ruleForm.Remark}}</span>
                </el-form-item>
              </el-col>
            </el-form>
          </el-tab-pane>
          <el-tab-pane label="详细信息" name="2">
            <div class="dis_flex flex_x_center">
              <showGoodsDetail ref="ShowGoodsDetail" :Memo="ruleForm.Memo" :ImageList="ruleForm.ImageList" :OriginalText="ruleForm.OriginalText" :Price="ruleForm.Price" :Name="ruleForm.Name" :isShowOriginal="false" GoodsType="储值卡">

                <el-row slot="info">
                  <el-col :span="24" class="padlt_10 padrt_10" style="height:40px;line-height:40px">商品信息</el-col>
                  <el-col :span="24" class="back_f8 pad_10">
                    <el-col :span="24" class="martp_5">有效期:
                      <span v-if="ruleForm.ValidType == 1">购买后 {{ruleForm.ValidDay}} 天内有效</span>
                      <span v-if="ruleForm.ValidType == 2">第一次使用后 {{ruleForm.ValidDay}} 天内有效</span>
                      <span v-if="ruleForm.ValidType == 3">永久有效</span>
                    </el-col>
                    <el-col :span="24" class="martp_5">适用门店: 适用共 {{ruleForm.ApplyNum}} 家门店 <span style="color:#FF8D66">查看门店</span></el-col>
                    <el-col :span="24" class="martp_5">本金适用商品: 适用共 {{goodsRangeList.length}} 个商品 <span style="color:#FF8D66">查看详情</span></el-col>
                    <el-col :span="24" class="martp_5">赠金适用商品: 适用共 {{goodsRangeLargessList.length}} 个商品 <span style="color:#FF8D66">查看详情</span></el-col>
                  </el-col>
                </el-row>
              </showGoodsDetail>
            </div>

          </el-tab-pane>
          <el-tab-pane label="设置阶梯价格" name="6" v-if="ruleForm.IsUseRechargeRules">
            <el-form :model="ruleForm" :rules="rules" ref="rechargeRulesRuleForm" size="small" label-width="0px" class="demo-ruleForm">
              <el-col class="dis_flex flex_dir_row" :span="24" v-for="(item,index) in ruleForm.SavingCardRechargeRules" :key="index">
                <el-form-item label="" :prop="'SavingCardRechargeRules.' + index + '.Price'">
                  充值<span>{{item.Price}}</span>元
                </el-form-item>
                <el-form-item label-width="20px" :prop="'SavingCardRechargeRules.' + index + '.LargessPrice'" :rules="rules.RechargeLargessPrice">
                  赠送<span>{{item.LargessPrice}}</span>元
                </el-form-item>

              </el-col>

            </el-form>
          </el-tab-pane>

          <el-tab-pane label="本金适用范围" name="4" v-if="!ruleForm.IsGoodsRange">
            <el-row :gutter="20" class="marbm_10">
              <el-col :span="6">
                <el-input placeholder="输入商品名称/分类名称搜索" size="small" v-model="filterGoodsRangeProjectName" clearable></el-input>
              </el-col>
              <el-col :span="12">
              </el-col>
            </el-row>
            <el-table size="small" max-height="400" :data="
                goodsRangeList.filter(
                  (data) =>
                    !filterGoodsRangeProjectName ||
                    data.Name.toLowerCase().includes(
                      filterGoodsRangeProjectName.toLowerCase()
                    )
                )
              ">
              <el-table-column prop="Name" label="商品名称/分类名称" sortable>
                <template slot-scope="scope">
                  <span>{{ scope.row.Name }}</span>
                  <el-tag type="info" size="mini" v-if="scope.row.IsGoods == 0">分类</el-tag>
                </template>
              </el-table-column>
              <el-table-column prop="cardType" label="商品类型" sortable></el-table-column>
              <el-table-column prop="Price" label="销售价格" sortable></el-table-column>
              <el-table-column prop="DiscountPrice" label="优惠规则">
                <template slot-scope="scope">
                  <span v-if="scope.row.IsGoods == 0">{{ scope.row.DiscountPrice }}折</span>
                  <span v-if="scope.row.IsGoods == 1 && scope.row.PriceType == 1">{{ scope.row.DiscountPrice }}折</span>
                  <span v-if="scope.row.IsGoods == 1 && scope.row.PriceType == 2">¥{{ scope.row.DiscountPrice }}</span>
                </template>
              </el-table-column>
            </el-table>
          </el-tab-pane>
          <el-tab-pane label="赠额使用范围" name="5" v-if="ruleForm.IsSettingLargess && !ruleForm.IsLargessGoodsRange">
            <el-row :gutter="20" class="marbm_10">
              <el-col :span="6">
                <el-input placeholder="输入商品名称/分类名称搜索" size="small" v-model="filterGoodsRangeLargessProjectName" clearable></el-input>
              </el-col>
              <el-col :span="12">
              </el-col>
            </el-row>
            <el-table max-height="400" size="small" :data="
                goodsRangeLargessList.filter(
                  (data) =>
                    !filterGoodsRangeLargessProjectName ||
                    data.Name.toLowerCase().includes(
                      filterGoodsRangeLargessProjectName.toLowerCase()
                    )
                )
              ">
              <el-table-column prop="Name" label="商品分类名称/商品名称" sortable>
                <template slot-scope="scope">
                  <span>{{ scope.row.Name }}</span>
                  <el-tag type="info" size="mini" v-if="scope.row.IsGoods == 0">分类</el-tag>
                </template>
              </el-table-column>
              <el-table-column prop="cardType" label="商品类型" sortable></el-table-column>
              <el-table-column prop="Price" label="销售价格" sortable></el-table-column>
              <el-table-column prop="DiscountPrice" label="优惠规则">
                <template slot-scope="scope">
                  <span v-if="scope.row.IsGoods == 0">{{ scope.row.DiscountPrice }}折</span>
                  <span v-if="scope.row.IsGoods == 1 && scope.row.PriceType == 1">{{ scope.row.DiscountPrice }}折</span>
                  <span v-if="scope.row.IsGoods == 1 && scope.row.PriceType == 2">¥{{ scope.row.DiscountPrice }}</span>
                </template>
              </el-table-column>
            </el-table>
          </el-tab-pane>
        </el-tabs>
      </div>
    </el-dialog>
    <!--使用范围弹框-->
    <el-dialog title="配置储值卡使用范围" :visible.sync="dialogScope" width="1000px">
      <div>
        <el-row :gutter="20">
          <el-col :span="8">
            <el-collapse accordion v-model="collapseIndex" @change="collapseChange">
              <el-collapse-item title="产品" name="1">
                <el-input v-model="productName" placeholder="请输入关键字进行搜索" clearable size="small"></el-input>
                <el-scrollbar class="el-scrollbar_height_range">
                  <el-tree ref="treeProduct" :expand-on-click-node="false" :check-on-click-node="true" :check-strictly="true" :data="productList" show-checkbox accordion node-key="PID" :props="{ children: 'Child', label: 'Name' }" :filter-node-method="filterNode" :default-checked-keys="productDefaultCheckedKeys" :default-expanded-keys="productDefaultExpandedKeys" @check="changeProductData">
                    <div slot-scope="{ node, data }">
                      <span>{{ data.Name }}</span>
                      <el-tag class="marlt_5" type="info" size="mini" v-if="data.IsGoods == 0">分类
                      </el-tag>
                    </div>
                  </el-tree>
                </el-scrollbar>
              </el-collapse-item>
              <el-collapse-item title="项目" name="2">
                <el-input v-model="projectName" placeholder="请输入关键字进行搜索" clearable size="small"></el-input>
                <el-scrollbar class="el-scrollbar_height_range">
                  <el-tree ref="treeProject" :expand-on-click-node="false" :check-on-click-node="true" :check-strictly="true" :data="projectList" show-checkbox accordion node-key="PID" :default-checked-keys="projectDefaultCheckedKeys" :default-expanded-keys="projectDefaultExpandedKeys" :props="{ children: 'Child', label: 'Name' }" :filter-node-method="filterNode" @check="changeProjectData">
                    <div slot-scope="{ node, data }">
                      <span>{{ data.Name }}</span>
                      <el-tag class="marlt_5" type="info" size="mini" v-if="data.IsGoods == 0">分类
                      </el-tag>
                    </div>
                  </el-tree>
                </el-scrollbar>
              </el-collapse-item>
              <el-collapse-item title="通用次卡" name="3">
                <el-input v-model="generalName" placeholder="请输入关键字进行搜索" clearable size="small"></el-input>
                <el-scrollbar class="el-scrollbar_height_range">
                  <el-tree ref="treeGeneral" :expand-on-click-node="false" :check-on-click-node="true" :check-strictly="true" :data="generalCardList" show-checkbox accordion node-key="PID" :default-checked-keys="generalDefaultCheckedKeys" :default-expanded-keys="generalDefaultExpandedKeys" :props="{ children: 'Child', label: 'Name' }" :filter-node-method="filterNode" @check="changeGeneralData">
                    <div slot-scope="{ node, data }">
                      <span>{{ data.Name }}</span>
                      <el-tag class="marlt_5" type="info" size="mini" v-if="data.IsGoods == 0">分类
                      </el-tag>
                    </div>
                  </el-tree>
                </el-scrollbar>
              </el-collapse-item>
              <el-collapse-item title="时效卡" name="4">
                <el-input v-model="timeName" placeholder="请输入关键字进行搜索" clearable size="small"></el-input>
                <el-scrollbar class="el-scrollbar_height_range">
                  <el-tree ref="treeTime" :expand-on-click-node="false" :check-on-click-node="true" :check-strictly="true" :data="timeCardList" show-checkbox accordion node-key="PID" :default-checked-keys="timeDefaultCheckedKeys" :default-expanded-keys="timeDefaultExpandedKeys" :props="{ children: 'Child', label: 'Name' }" :filter-node-method="filterNode" @check="changeTimeData">
                    <div slot-scope="{ node, data }">
                      <span>{{ data.Name }}</span>
                      <el-tag class="marlt_5" type="info" size="mini" v-if="data.IsGoods == 0">分类
                      </el-tag>
                    </div>
                  </el-tree>
                </el-scrollbar>
              </el-collapse-item>
              <el-collapse-item title="套餐卡" name="5">
                <el-input v-model="packageName" placeholder="请输入关键字进行搜索" clearable size="small"></el-input>
                <el-scrollbar class="el-scrollbar_height_range">
                  <el-tree ref="treePackage" :expand-on-click-node="false" :check-on-click-node="true" :check-strictly="true" :data="packageCardList" show-checkbox accordion node-key="PID" :default-checked-keys="packageDefaultCheckedKeys" :default-expanded-keys="packageDefaultExpandedKeys" :props="{ children: 'Child', label: 'Name' }" :filter-node-method="filterNode" @check="changePackageData">
                    <div slot-scope="{ node, data }">
                      <span>{{ data.Name }}</span>
                      <el-tag class="marlt_5" type="info" size="mini" v-if="data.IsGoods == 0">分类
                      </el-tag>
                    </div>
                  </el-tree>
                </el-scrollbar>
              </el-collapse-item>
            </el-collapse>
          </el-col>
          <el-col :span="16">
            <el-table max-height="500px" size="small" :data="
                savingCardProduct.filter(
                  (data) =>
                    !productName ||
                    data.Name.toLowerCase().includes(productName.toLowerCase())
                )
              " v-if="collapseIndex == 1">
              <el-table-column prop="Name" label="产品名称/分类名称">
                <template slot-scope="scope">
                  <span>{{ scope.row.Name }}</span>
                  <el-tag type="info" size="mini" v-if="scope.row.IsGoods == 0">分类</el-tag>
                </template>
              </el-table-column>
              <!-- <el-table-column prop="cardType" label="商品类型"></el-table-column> -->
              <el-table-column prop="Price" label="销售价格"></el-table-column>
              <el-table-column label="优惠设置">
                <template slot-scope="scope">
                  <div v-if="scope.row.IsGoods == 0">
                    <el-input v-model="scope.row.DiscountPrice" size="small" type="number" @input="priceTypeChange(scope.row)" v-enter-number2>
                      <template slot="append">折</template>
                    </el-input>
                  </div>
                  <div v-if="scope.row.IsGoods == 1">
                    <el-input v-model="scope.row.DiscountPrice" class="el_input_select" size="small" type="number" @input="priceTypeChange(scope.row)" v-enter-number2>
                      <template slot="append">
                        <el-select v-model="scope.row.PriceType" @change="priceTypeChange(scope.row)">
                          <el-option label="折" :value="1">折</el-option>
                          <el-option label="元" :value="2">元</el-option>
                        </el-select>
                      </template>
                    </el-input>
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="操作" width="80px">
                <template slot-scope="scope">
                  <el-button type="danger" size="small" @click="removetProject(1, scope.row)">删除
                  </el-button>
                </template>
              </el-table-column>
            </el-table>
            <el-table size="small" max-height="500px" :data="
                savingCardProject.filter(
                  (data) =>
                    !projectName ||
                    data.Name.toLowerCase().includes(projectName.toLowerCase())
                )
              " v-if="collapseIndex == 2">
              <el-table-column prop="Name" label="项目名称/分类名称">
                <template slot-scope="scope">
                  <span>{{ scope.row.Name }}</span>
                  <el-tag type="info" size="mini" v-if="scope.row.IsGoods == 0">分类</el-tag>
                </template>
              </el-table-column>
              <!-- <el-table-column prop="cardType" label="分类"></el-table-column> -->
              <el-table-column prop="Price" label="销售价格"></el-table-column>
              <el-table-column label="优惠设置">
                <template slot-scope="scope">
                  <div v-if="scope.row.IsGoods == 0">
                    <el-input v-model="scope.row.DiscountPrice" size="small" type="number" @input="priceTypeChange(scope.row)" v-enter-number2>
                      <template slot="append">折</template>
                    </el-input>
                  </div>
                  <div v-if="scope.row.IsGoods == 1">
                    <el-input v-model="scope.row.DiscountPrice" class="el_input_select" size="small" type="number" @input="priceTypeChange(scope.row)" v-enter-number2>
                      <template slot="append">
                        <el-select v-model="scope.row.PriceType" @change="priceTypeChange(scope.row)">
                          <el-option label="折" :value="1">折</el-option>
                          <el-option label="元" :value="2">元</el-option>
                        </el-select>
                      </template>
                    </el-input>
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="操作" width="80px">
                <template slot-scope="scope">
                  <el-button type="danger" size="small" @click="removetProject(2, scope.row)">删除
                  </el-button>
                </template>
              </el-table-column>
            </el-table>
            <el-table size="small" max-height="500px" :data="
                savingCardGeneral.filter(
                  (data) =>
                    !generalName ||
                    data.Name.toLowerCase().includes(generalName.toLowerCase())
                )
              " v-if="collapseIndex == 3">
              <el-table-column prop="Name" label="通用次卡名称/分类名称">
                <template slot-scope="scope">
                  <span>{{ scope.row.Name }}</span>
                  <el-tag type="info" size="mini" v-if="scope.row.IsGoods == 0">分类</el-tag>
                </template>
              </el-table-column>
              <!-- <el-table-column prop="cardType" label="分类"></el-table-column> -->
              <el-table-column prop="Price" label="销售价格"></el-table-column>
              <el-table-column label="优惠设置">
                <template slot-scope="scope">
                  <div v-if="scope.row.IsGoods == 0">
                    <el-input v-model="scope.row.DiscountPrice" size="small" type="number" @input="priceTypeChange(scope.row)" v-enter-number2>
                      <template slot="append">折</template>
                    </el-input>
                  </div>
                  <div v-if="scope.row.IsGoods == 1">
                    <el-input v-model="scope.row.DiscountPrice" class="el_input_select" size="small" type="number" @input="priceTypeChange(scope.row)" v-enter-number2>
                      <template slot="append">
                        <el-select v-model="scope.row.PriceType" @change="priceTypeChange(scope.row)">
                          <el-option label="折" :value="1">折</el-option>
                          <el-option label="元" :value="2">元</el-option>
                        </el-select>
                      </template>
                    </el-input>
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="操作" width="80px">
                <template slot-scope="scope">
                  <el-button type="danger" size="small" @click="removetProject(3, scope.row)">删除
                  </el-button>
                </template>
              </el-table-column>
            </el-table>
            <el-table max-height="500px" size="small" :data="
                savingCardTime.filter(
                  (data) =>
                    !timeName ||
                    data.Name.toLowerCase().includes(timeName.toLowerCase())
                )
              " v-if="collapseIndex == 4">
              <el-table-column prop="Name" label="时效卡名称/分类名称">
                <template slot-scope="scope">
                  <span>{{ scope.row.Name }}</span>
                  <el-tag type="info" size="mini" v-if="scope.row.IsGoods == 0">分类</el-tag>
                </template>
              </el-table-column>
              <!-- <el-table-column prop="cardType" label="分类"></el-table-column> -->
              <el-table-column prop="Price" label="销售价格"></el-table-column>
              <el-table-column label="优惠设置">
                <template slot-scope="scope">
                  <div v-if="scope.row.IsGoods == 0">
                    <el-input v-model="scope.row.DiscountPrice" size="small" type="number" @input="priceTypeChange(scope.row)" v-enter-number2>
                      <template slot="append">折</template>
                    </el-input>
                  </div>
                  <div v-if="scope.row.IsGoods == 1">
                    <el-input v-model="scope.row.DiscountPrice" class="el_input_select" size="small" type="number" @input="priceTypeChange(scope.row)" v-enter-number2>
                      <template slot="append">
                        <el-select v-model="scope.row.PriceType" @change="priceTypeChange(scope.row)">
                          <el-option label="折" :value="1">折</el-option>
                          <el-option label="元" :value="2">元</el-option>
                        </el-select>
                      </template>
                    </el-input>
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="操作" width="80px">
                <template slot-scope="scope">
                  <el-button type="danger" size="small" @click="removetProject(4, scope.row)">删除
                  </el-button>
                </template>
              </el-table-column>
            </el-table>
            <el-table size="small" max-height="500px" :data="
                savingCardPackage.filter(
                  (data) =>
                    !packageName ||
                    data.Name.toLowerCase().includes(packageName.toLowerCase())
                )
              " v-if="collapseIndex == 5">
              <el-table-column prop="Name" label="套餐卡名称/分类名称">
                <template slot-scope="scope">
                  <span>{{ scope.row.Name }}</span>
                  <el-tag type="info" size="mini" v-if="scope.row.IsGoods == 0">分类</el-tag>
                </template>
              </el-table-column>
              <!-- <el-table-column prop="cardType" label="分类"></el-table-column> -->
              <el-table-column prop="Price" label="销售价格"></el-table-column>
              <el-table-column label="优惠设置">
                <template slot-scope="scope">
                  <div v-if="scope.row.IsGoods == 0">
                    <el-input v-model="scope.row.DiscountPrice" size="small" type="number" @input="priceTypeChange(scope.row)" v-enter-number2>
                      <template slot="append">折</template>
                    </el-input>
                  </div>
                  <div v-if="scope.row.IsGoods == 1">
                    <el-input v-model="scope.row.DiscountPrice" class="el_input_select" size="small" type="number" @input="priceTypeChange(scope.row)" v-enter-number2>
                      <template slot="append">
                        <el-select v-model="scope.row.PriceType" @change="priceTypeChange(scope.row)">
                          <el-option label="折" :value="1">折</el-option>
                          <el-option label="元" :value="2">元</el-option>
                        </el-select>
                      </template>
                    </el-input>
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="操作" width="80px">
                <template slot-scope="scope">
                  <el-button type="danger" size="small" @click="removetProject(5, scope.row)">删除
                  </el-button>
                </template>
              </el-table-column>
            </el-table>

            <!--展示所有-->
            <el-table size="small" max-height="500px" :data="savingcardSelectAll" v-if="collapseIndex == ''">
              <el-table-column prop="Name" label="商品名称/分类名称">
                <template slot-scope="scope">
                  <span>{{ scope.row.Name }}</span>
                  <el-tag type="info" size="mini" v-if="scope.row.IsGoods == 0">分类</el-tag>
                </template>
              </el-table-column>
              <el-table-column prop="cardType" label="商品类型" width="100px"></el-table-column>
              <el-table-column prop="Price" label="销售价格">
                <template slot-scope="scope">
                  <div v-if="scope.row.Price != undefined">
                    {{ scope.row.Price }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="优惠设置" min-width="100px">
                <template slot-scope="scope">
                  <div v-if="scope.row.IsGoods == 0">
                    <el-input v-model="scope.row.DiscountPrice" size="small" type="number" @input="priceTypeChange(scope.row)" v-enter-number2>
                      <template slot="append">折</template>
                    </el-input>
                  </div>
                  <div v-if="scope.row.IsGoods == 1">
                    <el-input v-model="scope.row.DiscountPrice" class="el_input_select" size="small" type="number" @input="priceTypeChange(scope.row)" v-enter-number2>
                      <template slot="append">
                        <el-select v-model="scope.row.PriceType" @change="priceTypeChange(scope.row)">
                          <el-option label="折" :value="1">折</el-option>
                          <el-option label="元" :value="2">元</el-option>
                        </el-select>
                      </template>
                    </el-input>
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="操作" width="80px">
                <template slot-scope="scope">
                  <el-button type="danger" size="small" @click="removetProject(scope.row.isCardType, scope.row)">删除
                  </el-button>
                </template>
              </el-table-column>
            </el-table>
          </el-col>
        </el-row>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button size="small" @click="dialogScope = false">取 消</el-button>
        <el-button size="small" type="primary" @click="addProjectSelect">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
var Enumerable = require("linq");
import APICategory from "@/api/iBeauty/Basic/savingCardCategory";
import APIEntity from "@/api/KHS/Entity/entity";
import API from "@/api/iBeauty/Goods/savingCard";
import permission from "@/components/js/permission.js";
import goodsDetail from "../Goods/Components/GoodsDetail.vue";
import utils from "@/components/js/utils.js";
import showGoodsDetail from "../Goods/Components/ShowGoodsDetail.vue";

export default {
  name: "SavingCard",
  /**  引入的组件  */
  components: {
    goodsDetail,
    showGoodsDetail,
  },
  data() {
    return {
      loading: false,
      updateLoading: false,
      modalLoading: false,
      dialogVisible: false,
      showDialogVisible: false, //查看弹出层
      dialogScope: false,
      isAdd: true,
      activeName: "1",
      name: "",
      savingCardCategoryID: null, //储值卡分类ID
      isAllowSell: true, // 是否上架
      savingCardId: "",
      category: [], //分类
      tableData: [],
      saleEntityList: [], //销售范围
      consumeEntityList: [], //消耗范围
      defaultCheckedKeysSale: [],
      defaultExpandedKeysSale: [1],
      defaultCheckedKeysConsume: [],
      defaultExpandedKeysConsume: [1],
      isLargess: 1, //本金增额
      collapseIndex: "", // 默认卡项
      productName: "",
      productList: [], //产品
      productDefaultCheckedKeys: [], //回显
      productDefaultExpandedKeys: [1],
      projectName: "",
      projectList: [], //项目
      projectDefaultCheckedKeys: [], //回显
      projectDefaultExpandedKeys: [1],
      generalName: "",
      generalCardList: [], //通用次卡
      generalDefaultCheckedKeys: [], //回显
      generalDefaultExpandedKeys: [1],
      timeName: "",
      timeCardList: [], //时效卡
      timeDefaultCheckedKeys: [], //回显
      timeDefaultExpandedKeys: [1],
      packageName: "",
      packageCardList: [], //套餐卡
      packageDefaultCheckedKeys: [], //回显
      packageDefaultExpandedKeys: [1],
      savingCardProduct: [], //选中适用产品
      savingCardProject: [], //选中适用项目
      savingCardGeneral: [], //选中适用项目
      savingCardTime: [], //选中适用项目
      savingCardPackage: [], //选中适用项目
      savingcardSelectAll: [],

      filterGoodsRangeProjectName: "", //本金项目过滤
      goodsRangeList: [],
      goodsRangeProductList: [],
      goodsRangeProjectList: [],
      goodsRangeGeneralCardList: [],
      goodsRangeTimeCardList: [],
      goodsRangePackageCardList: [],
      filterGoodsRangeLargessProjectName: "", //赠金项目过滤
      goodsRangeLargessList: [],
      goodsRangeLargessProductList: [],
      goodsRangeLargessProjectList: [],
      goodsRangeLargessGeneralCardList: [],
      goodsRangeLargessTimeCardList: [],
      goodsRangeLargessPackageCardList: [],
      ruleForm: {
        Name: "", //储值卡名称
        Alias: "", //别名
        SavingCardCategoryID: "", //储值卡类别ID
        Price: "", //售价
        LargessPrice: "", //赠送金额
        IsAllowSell: true, //是否上架。值为true/false
        SaleStartDate: "", //售卖开始时间
        SaleEndingDate: "", //售卖结束时间
        saleDate: [],
        ValidType: 3, //有效期类型（1：销售后生成、2：消耗后生成、3：永久有效）
        ValidDay: "", //有效期天数（单位：天）
        IsSettingLargess: false, //是否设置赠额使用范围，值为ture/false
        IsAllowSaleDeduct: true, //是否销售抵扣，值为ture/false
        IsAllowConsumeDeduct: true, //是否消耗抵扣，值为ture/false
        IsGoodsRange: true, //是否有权益范围，值为ture/false
        IsLargessGoodsRange: true, // 赠送是否有权益范围，值为ture/false
        SavingCardSaleEntity: [], //销售范围
        SavingCardConsumeEntity: [], //消耗范围
        IsUseRechargeRules: false, // 使用阶梯价格赠送
        SavingCardRechargeRules: [{ Price: "", LargessPrice: "" }], // 阶梯价格集合
        IsModifyPrice: true, // 本金是否可修改
        IsModifyLargessPrice: true, // 赠金是否可修改
        IsReturnedCommission:false,
        GoodsRange: {
          Product: [], //产品集合
          ProductCategory: [], //产品分类集合
          Project: [], //项目集合
          ProjectCategory: [], //项目分类集合
          GeneralCard: [], //通用次卡集合
          GeneralCardCategory: [], //通用次卡分类集合
          TimeCard: [], //时效卡集合
          TimeCardCategory: [], //时效卡分类集合
          PackageCard: [], //套餐卡集合
          PackageCardCategory: [], //套餐卡分类集合
        }, //商品集合
        GoodsRangeLargess: {
          Product: [], //产品集合
          ProductCategory: [], //产品分类集合
          Project: [], //项目集合
          ProjectCategory: [], //项目分类集合
          GeneralCard: [], //通用次卡集合
          GeneralCardCategory: [], //通用次卡分类集合
          TimeCard: [], //时效卡集合
          TimeCardCategory: [], //时效卡分类集合
          PackageCard: [], //套餐卡集合
          PackageCardCategory: [], //套餐卡分类集合
        }, //赠额可用范围

        ID: "", //商品ID
        PriceType: "", //价格类型（1：折扣、2：价格）
        IsLargess: "", //是否赠送 ，值为ture/false
        DiscountPrice: "", //折扣/价格
        CategoryID: "", //分类ID
        Remark: "",

        Memo: "",
        ImageList: [],
      },
      rules: {
        Name: [
          { required: true, message: "请输入储值卡名称", trigger: "blur" },
        ],
        Alias: [
          { required: true, message: "请输入储值卡别名", trigger: "blur" },
        ],
        SavingCardCategoryID: [
          { required: true, message: "请选择储值卡类别", trigger: "change" },
        ],
        Price: [
          {
            required: true,
            message: "请输入储值卡金额",
            trigger: ["blur", "change"],
          },
        ],
        LargessPrice: [
          { required: true, message: "请输入储值卡赠送金额", trigger: "blur" },
        ],
        RechargePrice: [
          {
            required: true,
            message: "请输入储值卡金额",
            trigger: ["blur", "change"],
          },
        ],
        RechargeLargessPrice: [
          {
            required: true,
            message: "请输入储值卡赠额",
            trigger: ["blur", "change"],
          },
        ],
        IsAllowSell: [
          { required: true, message: "请选择是否上架", trigger: "change" },
        ],
        saleDate: [
          { required: true, message: "请选择售卖时间", trigger: "change" },
        ],
        ValidType: [
          { required: true, message: "请选择有效期类型", trigger: "change" },
        ],
        ValidDay: [
          { required: true, message: "请输入有效期天数", trigger: "blur" },
        ],
        IsSettingLargess: [
          {
            required: true,
            message: "请选择是否设置增额使用范围",
            trigger: "change",
          },
        ],
        IsAllowSaleDeduct: [
          { required: true, message: "请选择是否销售抵扣", trigger: "change" },
        ],
        IsAllowConsumeDeduct: [
          { required: true, message: "请选择是否消耗抵扣", trigger: "change" },
        ],
        IsGoodsRange: [
          {
            required: true,
            message: "请选择是否有权益范围",
            trigger: "change",
          },
        ],
        IsLargessGoodsRange: [
          {
            required: true,
            message: "请选择赠送是否有权益范围",
            trigger: "change",
          },
        ],
        SavingCardSaleEntity: [
          { required: true, message: "请选择销售范围", trigger: "change" },
        ],
        SavingCardConsumeEntity: [
          { required: true, message: "请选择消耗范围", trigger: "change" },
        ],
        GoodsRange: [
          { required: true, message: "请选择商品集合", trigger: "change" },
        ],
        GoodsRangeLargess: [
          { required: true, message: "请选择增额可用范围", trigger: "change" },
        ],
        Product: [
          { required: true, message: "请选择产品集合", trigger: "change" },
        ],
        ProductCategory: [
          { required: true, message: "请选择产品分类集合", trigger: "change" },
        ],
        GeneralCard: [
          { required: true, message: "请选择通用次卡集合", trigger: "change" },
        ],
        GeneralCardCategory: [
          {
            required: true,
            message: "请选择通用次卡分类集合",
            trigger: "change",
          },
        ],
        TimeCard: [
          { required: true, message: "请选择时效卡集合", trigger: "change" },
        ],
        TimeCardCategory: [
          {
            required: true,
            message: "请选择时效卡分类集合",
            trigger: "change",
          },
        ],
        PackageCard: [
          { required: true, message: "请选择套餐卡集合", trigger: "change" },
        ],
        PackageCardCategory: [
          {
            required: true,
            message: "请选择套餐卡分类集合",
            trigger: "change",
          },
        ],
      },
      paginations: {
        page: 1, // 当前位于哪页
        total: 0, // 总数
        page_size: 10, // 1页显示多少条
        layout: "total, prev, pager, next,jumper", // 翻页属性
      },
      defaultProps: {
        children: "Child",
        label: "EntityName",
      },
      isSavingCardEdit: "", //编辑权限
      isSavingCardNew: "", //新增权限
    };
  },
  computed: {
    SavingCardCategory: function () {
      if (!this.ruleForm.SavingCardCategoryID) return "";
      const value = this.ruleForm.SavingCardCategoryID.toString();
      const item = this.category.find((item) => item.ID == value);
      return item.Name;
    },
    saleDate: function () {
      return (
        this.ruleForm.SaleStartDate +
        " " +
        "至" +
        " " +
        this.ruleForm.SaleEndingDate
      );
    },
  },
  watch: {
    productName(val) {
      this.$refs.treeProduct.filter(val);
    },
    projectName(val) {
      this.$refs.treeProject.filter(val);
    },
    generalName(val) {
      this.$refs.treeGeneral.filter(val);
    },
    timeName(val) {
      this.$refs.treeTime.filter(val);
    },
    packageName(val) {
      this.$refs.treePackage.filter(val);
    },
  },
  methods: {
    // //状态显示转换
    formatStatus: function (row) {
      return row.IsAllowSell ? "是" : "否";
    },
    filterNode(value, data) {
      if (!value) return true;
      return data.Name.indexOf(value) !== -1;
    },
    // 分类数据
    categoryData: function () {
      var that = this;
      that.loading = true;
      var params = {
        Name: "",
        Active: true,
      };
      APICategory.getSavingCardCategory(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.category = res.Data;
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.loading = false;
        });
    },
    // 数据显示
    handleSearch: function () {
      let that = this;
      that.paginations.page = 1;
      that.search();
    },
    // 数据显示
    search: function () {
      let that = this;
      that.loading = true;
      var params = {
        Name: that.name,
        SavingCardCategoryID: that.savingCardCategoryID,
        IsAllowSell: that.isAllowSell,
        PageNum: that.paginations.page,
      };
      API.getSavingCard(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.tableData = res.List;
            that.paginations.total = res.Total;
            that.paginations.page_size = res.PageSize;
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.loading = false;
        });
    },
    // 上下分页
    handleCurrentChange(page) {
      var that = this;
      that.paginations.page = page;
      that.search();
    },
    // 销售消耗范围
    entityData: function () {
      var that = this;
      that.loading = true;
      var params = {
        SearchKey: "",
        Active: true,
      };
      APIEntity.getEntity(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.entityList = res.Data;
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.loading = false;
        });
    },
    // 储值卡销售范围
    saleEntityData: function () {
      var that = this;
      that.loading = true;
      var params = {
        ID: that.savingCardId,
      };
      API.getSavingCardSaleEntity(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.defaultCheckedKeysSale = res.Data;
            that.defaultExpandedKeysSale = res.Data;
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.loading = false;
        });
    },
    // 储值卡消耗范围
    consumeEntityData: function () {
      var that = this;
      that.loading = true;
      var params = {
        ID: that.savingCardId,
      };
      API.getSavingCardConsumeEntity(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.defaultCheckedKeysConsume = res.Data;
            that.defaultExpandedKeysConsume = res.Data;
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.loading = false;
        });
    },
    // 获取储值卡适用项目
    savingCardProjectData: function (IsLargess) {
      var that = this;
      that.loading = true;
      var params = {
        ID: that.savingCardId,
        IsLargess: IsLargess,
      };
      API.getSavingCardGoods(params)
        .then((res) => {
          if (res.StateCode == 200) {
            if (res.Data.Product.length > 0) {
              res.Data.Product = Enumerable.from(res.Data.Product)
                .select((val) => ({
                  PID: "1" + val.ID,
                  ID: val.ID,
                  IsGoods: 1,
                  Name: val.Name,
                  Price: val.Price,
                  PriceType: val.PriceType,
                  DiscountPrice: val.DiscountPrice,
                  cardType: "产品",
                  isCardType: 1,
                }))
                .toArray();
            }
            if (res.Data.ProductCategory.length > 0) {
              res.Data.ProductCategory = Enumerable.from(
                res.Data.ProductCategory
              )
                .select((val) => ({
                  PID: "0" + val.CategoryID,
                  ID: val.CategoryID,
                  IsGoods: 0,
                  Name: val.Name,
                  Price: "",
                  DiscountPrice: val.Discount,
                  PriceType: 1,
                  cardType: "产品",
                  isCardType: 1,
                }))
                .toArray();
            }
            if (res.Data.Project.length > 0) {
              res.Data.Project = Enumerable.from(res.Data.Project)
                .select((val) => ({
                  PID: "1" + val.ID,
                  ID: val.ID,
                  IsGoods: 1,
                  Name: val.Name,
                  Price: val.Price,
                  PriceType: val.PriceType,
                  DiscountPrice: val.DiscountPrice,
                  cardType: "项目",
                  isCardType: 2,
                }))
                .toArray();
            }
            if (res.Data.ProjectCategory.length > 0) {
              res.Data.ProjectCategory = Enumerable.from(
                res.Data.ProjectCategory
              )
                .select((val) => ({
                  PID: "0" + val.CategoryID,
                  ID: val.CategoryID,
                  IsGoods: 0,
                  Name: val.Name,
                  Price: "",
                  DiscountPrice: val.Discount,
                  PriceType: 1,
                  cardType: "项目",
                  isCardType: 2,
                }))
                .toArray();
            }
            if (res.Data.GeneralCard.length > 0) {
              res.Data.GeneralCard = Enumerable.from(res.Data.GeneralCard)
                .select((val) => ({
                  PID: "1" + val.ID,
                  ID: val.ID,
                  IsGoods: 1,
                  Name: val.Name,
                  Price: val.Price,
                  PriceType: val.PriceType,
                  DiscountPrice: val.DiscountPrice,
                  cardType: "通用次卡",
                  isCardType: 3,
                }))
                .toArray();
            }
            if (res.Data.GeneralCardCategory.length > 0) {
              res.Data.GeneralCardCategory = Enumerable.from(
                res.Data.GeneralCardCategory
              )
                .select((val) => ({
                  PID: "0" + val.CategoryID,
                  ID: val.CategoryID,
                  IsGoods: 0,
                  Name: val.Name,
                  Price: "",
                  DiscountPrice: val.Discount,
                  PriceType: 1,
                  cardType: "通用次卡",
                  isCardType: 3,
                }))
                .toArray();
            }
            if (res.Data.TimeCard.length > 0) {
              res.Data.TimeCard = Enumerable.from(res.Data.TimeCard)
                .select((val) => ({
                  PID: "1" + val.ID,
                  ID: val.ID,
                  IsGoods: 1,
                  Name: val.Name,
                  Price: val.Price,
                  PriceType: val.PriceType,
                  DiscountPrice: val.DiscountPrice,
                  cardType: "时效卡",
                  isCardType: 4,
                }))
                .toArray();
            }
            if (res.Data.TimeCardCategory.length > 0) {
              res.Data.TimeCardCategory = Enumerable.from(
                res.Data.TimeCardCategory
              )
                .select((val) => ({
                  PID: "0" + val.CategoryID,
                  ID: val.CategoryID,
                  IsGoods: 0,
                  Name: val.Name,
                  Price: "",
                  DiscountPrice: val.Discount,
                  PriceType: 1,
                  cardType: "时效卡",
                  isCardType: 4,
                }))
                .toArray();
            }
            if (res.Data.PackageCard.length > 0) {
              res.Data.PackageCard = Enumerable.from(res.Data.PackageCard)
                .select((val) => ({
                  PID: "1" + val.ID,
                  ID: val.ID,
                  IsGoods: 1,
                  Name: val.Name,
                  Price: val.Price,
                  PriceType: val.PriceType,
                  DiscountPrice: val.DiscountPrice,
                  cardType: "套餐卡",
                  isCardType: 5,
                }))
                .toArray();
            }
            if (res.Data.PackageCardCategory.length > 0) {
              res.Data.PackageCardCategory = Enumerable.from(
                res.Data.PackageCardCategory
              )
                .select((val) => ({
                  PID: "0" + val.CategoryID,
                  ID: val.CategoryID,
                  IsGoods: 0,
                  Name: val.Name,
                  Price: "",
                  DiscountPrice: val.Discount,
                  PriceType: 1,
                  cardType: "套餐卡",
                  isCardType: 5,
                }))
                .toArray();
            }
            if (!IsLargess) {
              that.ruleForm.GoodsRange = {
                Product: res.Data.Product,
                ProductCategory: res.Data.ProductCategory,
                Project: res.Data.Project,
                ProjectCategory: res.Data.ProjectCategory,
                GeneralCard: res.Data.GeneralCard,
                GeneralCardCategory: res.Data.GeneralCardCategory,
                TimeCard: res.Data.TimeCard,
                TimeCardCategory: res.Data.TimeCardCategory,
                PackageCard: res.Data.PackageCard,
                PackageCardCategory: res.Data.PackageCardCategory,
              };
              var goodsRangeProductList = res.Data.ProductCategory.concat(
                res.Data.Product
              );
              var goodsRangeProjectList = res.Data.ProjectCategory.concat(
                res.Data.Project
              );
              var goodsRangeGeneralCardList =
                res.Data.GeneralCardCategory.concat(res.Data.GeneralCard);
              var goodsRangeTimeCardList = res.Data.TimeCardCategory.concat(
                res.Data.TimeCard
              );
              var goodsRangePackageCardList =
                res.Data.PackageCardCategory.concat(res.Data.PackageCard);
              that.goodsRangeProductList = Object.assign(
                [],
                goodsRangeProductList
              );
              that.goodsRangeProjectList = Object.assign(
                [],
                goodsRangeProjectList
              );
              that.goodsRangeGeneralCardList = Object.assign(
                [],
                goodsRangeGeneralCardList
              );
              that.goodsRangeTimeCardList = Object.assign(
                [],
                goodsRangeTimeCardList
              );
              that.goodsRangePackageCardList = Object.assign(
                [],
                goodsRangePackageCardList
              );
              that.goodsRangeList = that.goodsRangeProductList.concat(
                that.goodsRangeProjectList,
                that.goodsRangeGeneralCardList,
                that.goodsRangeTimeCardList,
                that.goodsRangePackageCardList
              );
            } else {
              that.ruleForm.GoodsRangeLargess = {
                Product: res.Data.Product,
                ProductCategory: res.Data.ProductCategory,
                Project: res.Data.Project,
                ProjectCategory: res.Data.ProjectCategory,
                GeneralCard: res.Data.GeneralCard,
                GeneralCardCategory: res.Data.GeneralCardCategory,
                TimeCard: res.Data.TimeCard,
                TimeCardCategory: res.Data.TimeCardCategory,
                PackageCard: res.Data.PackageCard,
                PackageCardCategory: res.Data.PackageCardCategory,
              };
              var goodsRangeLargessProductList =
                res.Data.ProductCategory.concat(res.Data.Product);
              var goodsRangeLargessProjectList =
                res.Data.ProjectCategory.concat(res.Data.Project);
              var goodsRangeLargessGeneralCardList =
                res.Data.GeneralCardCategory.concat(res.Data.GeneralCard);
              var goodsRangeLargessTimeCardList =
                res.Data.TimeCardCategory.concat(res.Data.TimeCard);
              var goodsRangeLargessPackageCardList =
                res.Data.PackageCardCategory.concat(res.Data.PackageCard);
              that.goodsRangeLargessProductList = Object.assign(
                [],
                goodsRangeLargessProductList
              );
              that.goodsRangeLargessProjectList = Object.assign(
                [],
                goodsRangeLargessProjectList
              );
              that.goodsRangeLargessGeneralCardList = Object.assign(
                [],
                goodsRangeLargessGeneralCardList
              );
              that.goodsRangeLargessTimeCardList = Object.assign(
                [],
                goodsRangeLargessTimeCardList
              );
              that.goodsRangeLargessPackageCardList = Object.assign(
                [],
                goodsRangeLargessPackageCardList
              );
              that.goodsRangeLargessList =
                that.goodsRangeLargessProductList.concat(
                  that.goodsRangeLargessProjectList,
                  that.goodsRangeLargessGeneralCardList,
                  that.goodsRangeLargessTimeCardList,
                  that.goodsRangeLargessPackageCardList
                );
            }
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.loading = false;
        });
    },
    // 新增
    showAddDialog: function () {
      var that = this;
      that.activeName = "1";
      that.ruleForm = {
        Name: "", //储值卡名称
        Alias: "", //别名
        SavingCardCategoryID: "", //储值卡类别ID
        Price: "", //售价
        LargessPrice: "", //赠送金额
        IsAllowSell: true, //是否上架。值为true/false
        IsReturnedCommission:false, // 是否为返佣卡 默认false
        SaleStartDate: "", //售卖开始时间
        SaleEndingDate: "", //售卖结束时间
        saleDate: [],
        ValidType: 3, //有效期类型（1：销售后生成、2：消耗后生成、3：永久有效）
        ValidDay: "", //有效期天数（单位：天）
        IsSettingLargess: false, //是否设置赠额使用范围，值为ture/false
        IsAllowSaleDeduct: true, //是否销售抵扣，值为ture/false
        IsAllowConsumeDeduct: true, //是否消耗抵扣，值为ture/false
        IsGoodsRange: true, //是否有权益范围，值为ture/false
        IsLargessGoodsRange: true, // 赠送是否有权益范围，值为ture/false
        SavingCardSaleEntity: [], //销售范围
        SavingCardConsumeEntity: [], //消耗范围
        IsUseRechargeRules: false, // 使用阶梯价格赠送
        SavingCardRechargeRules: [{ Price: "", LargessPrice: "" }], // 阶梯价格集合
        IsModifyPrice: true, // 本金是否可修改
        IsModifyLargessPrice: true, // 赠金是否可修改
        GoodsRange: {
          Product: [], //产品集合
          ProductCategory: [], //产品分类集合
          Project: [], //项目集合
          ProjectCategory: [], //项目分类集合
          GeneralCard: [], //通用次卡集合
          GeneralCardCategory: [], //通用次卡分类集合
          TimeCard: [], //时效卡集合
          TimeCardCategory: [], //时效卡分类集合
          PackageCard: [], //套餐卡集合
          PackageCardCategory: [], //套餐卡分类集合
        }, //商品集合
        GoodsRangeLargess: {
          Product: [], //产品集合
          ProductCategory: [], //产品分类集合
          Project: [], //项目集合
          ProjectCategory: [], //项目分类集合
          GeneralCard: [], //通用次卡集合
          GeneralCardCategory: [], //通用次卡分类集合
          TimeCard: [], //时效卡集合
          TimeCardCategory: [], //时效卡分类集合
          PackageCard: [], //套餐卡集合
          PackageCardCategory: [], //套餐卡分类集合
        }, //赠额可用范围
        Memo: "",
        ImageList: [],
      };
      that.isAdd = true;
      that.defaultCheckedKeysSale = [];
      that.defaultExpandedKeysSale = [1];
      that.defaultCheckedKeysConsume = [];
      that.defaultExpandedKeysConsume = [1];
      that.productList = Object.assign([], that.productList);
      that.projectList = Object.assign([], that.projectList);
      that.generalCardList = Object.assign([], that.generalCardList);
      that.timeCardList = Object.assign([], that.timeCardList);
      that.packageCardList = Object.assign([], that.packageCardList);
      that.savingCardProduct = []; //选中适用产品
      that.savingCardProject = []; //选中适用项目
      that.savingCardGeneral = []; //选中适用项目
      that.savingCardTime = []; //选中适用项目
      that.savingCardPackage = []; //选中适用项目
      that.productDefaultCheckedKeys = []; //回显
      that.productDefaultExpandedKeys = [1];
      that.projectDefaultCheckedKeys = []; //回显
      that.projectDefaultExpandedKeys = [1];
      that.generalDefaultCheckedKeys = []; //回显
      that.generalDefaultExpandedKeys = [1];
      that.timeDefaultCheckedKeys = []; //回显
      that.timeDefaultExpandedKeys = [1];
      that.packageDefaultCheckedKeys = []; //回显
      that.packageDefaultExpandedKeys = [1];
      that.saleEntityList = [];
      that.consumeEntityList = [];
      that.filterGoodsRangeProjectName = ""; //本金项目过滤
      that.goodsRangeList = [];
      that.goodsRangeProductList = [];
      that.goodsRangeProjectList = [];
      that.goodsRangeGeneralCardList = [];
      that.goodsRangeTimeCardList = [];
      that.goodsRangePackageCardList = [];
      that.filterGoodsRangeLargessProjectName = ""; //赠金项目过滤
      that.goodsRangeLargessList = [];
      that.goodsRangeLargessProductList = [];
      that.goodsRangeLargessProjectList = [];
      that.goodsRangeLargessGeneralCardList = [];
      that.goodsRangeLargessTimeCardList = [];
      that.goodsRangeLargessPackageCardList = [];
      Object.assign(that.saleEntityList, that.entityList);
      Object.assign(that.consumeEntityList, that.entityList);

      that.categoryData();
      that.entityData();
      that.productData();
      that.projectData();
      that.generalCardData();
      that.timeCardData();
      that.packageCardData();
      that.dialogVisible = true;
    },
    // 编辑
    showEditDialog: function (row, type) {
      var that = this;
      that.isAdd = false;
      that.savingCardId = row.ID;
      that.activeName = "1";
      // that.ruleForm = Object.assign({}, row);
      that.ruleForm = JSON.parse(JSON.stringify(row)); // 深拷贝对象
      that.$set(that.ruleForm, "saleDate", [
        new Date(row.SaleStartDate),
        new Date(row.SaleEndingDate),
      ]);
      that.ruleForm.GoodsRange = {
        Product: [],
        ProductCategory: [],
        Project: [],
        ProjectCategory: [],
        GeneralCard: [],
        GeneralCardCategory: [],
        TimeCard: [],
        TimeCardCategory: [],
        PackageCard: [],
        PackageCardCategory: [],
      };
      that.ruleForm.GoodsRangeLargess = {
        Product: [],
        ProductCategory: [],
        Project: [],
        ProjectCategory: [],
        GeneralCard: [],
        GeneralCardCategory: [],
        TimeCard: [],
        TimeCardCategory: [],
        PackageCard: [],
        PackageCardCategory: [],
      };

      that.defaultCheckedKeysSale = [];
      that.defaultExpandedKeysSale = [1];
      that.defaultCheckedKeysConsume = [];
      that.defaultExpandedKeysConsume = [1];
      that.saleEntityList = [];
      that.consumeEntityList = [];
      Object.assign(that.saleEntityList, that.entityList);
      Object.assign(that.consumeEntityList, that.entityList);
      that.saleEntityData();
      that.consumeEntityData();
      if (row.IsSettingLargess) {
        if (!row.IsLargessGoodsRange) {
          that.savingCardProjectData(true);
        }
        if (!row.IsGoodsRange) {
          that.savingCardProjectData(false);
        }
      } else {
        if (!row.IsGoodsRange) {
          that.savingCardProjectData(false);
        }
      }

      that.categoryData();
      that.entityData();
      that.productData();
      that.projectData();
      that.generalCardData();
      that.timeCardData();
      that.packageCardData();
      that.getSavingCardDetails(that.ruleForm.ID);

      that.dialogVisible = type == "edit" ? true : false;
      that.showDialogVisible = type == "show" ? true : false;
    },
    // 适用项目
    addProjectDialog: function (index) {
      var that = this;
      that.isLargess = index;
      that.productList = Object.assign([], that.productList);
      that.projectList = Object.assign([], that.projectList);
      that.generalCardList = Object.assign([], that.generalCardList);
      that.timeCardList = Object.assign([], that.timeCardList);
      that.packageCardList = Object.assign([], that.packageCardList);
      that.savingCardProduct = []; //选中适用产品
      that.savingCardProject = []; //选中适用项目
      that.savingCardGeneral = []; //选中适用项目
      that.savingCardTime = []; //选中适用项目
      that.savingCardPackage = []; //选中适用项目
      that.productDefaultCheckedKeys = []; //回显
      that.productDefaultExpandedKeys = [1];
      that.projectDefaultCheckedKeys = []; //回显
      that.projectDefaultExpandedKeys = [1];
      that.generalDefaultCheckedKeys = []; //回显
      that.generalDefaultExpandedKeys = [1];
      that.timeDefaultCheckedKeys = []; //回显
      that.timeDefaultExpandedKeys = [1];
      that.packageDefaultCheckedKeys = []; //回显
      that.packageDefaultExpandedKeys = [1];
      that.savingcardSelectAll = [];
      if (index == 1) {
        Object.assign(that.savingCardProduct, that.goodsRangeProductList);
        Object.assign(that.savingCardProject, that.goodsRangeProjectList);
        Object.assign(that.savingCardGeneral, that.goodsRangeGeneralCardList);
        Object.assign(that.savingCardTime, that.goodsRangeTimeCardList);
        Object.assign(that.savingCardPackage, that.goodsRangePackageCardList);
        that.savingcardSelectAll = that.goodsRangeProductList.concat(
          that.goodsRangeProjectList,
          that.goodsRangeGeneralCardList,
          that.goodsRangeTimeCardList,
          that.goodsRangePackageCardList
        );
      } else {
        Object.assign(
          that.savingCardProduct,
          that.goodsRangeLargessProductList
        );
        Object.assign(
          that.savingCardProject,
          that.goodsRangeLargessProjectList
        );
        Object.assign(
          that.savingCardGeneral,
          that.goodsRangeLargessGeneralCardList
        );
        Object.assign(that.savingCardTime, that.goodsRangeLargessTimeCardList);
        Object.assign(
          that.savingCardPackage,
          that.goodsRangeLargessPackageCardList
        );
        that.savingcardSelectAll = that.goodsRangeLargessProductList.concat(
          that.goodsRangeLargessProjectList,
          that.goodsRangeLargessGeneralCardList,
          that.goodsRangeLargessTimeCardList,
          that.goodsRangeLargessPackageCardList
        );
      }
      that.productDefaultCheckedKeys = Enumerable.from(that.savingCardProduct)
        .select((val) => val.PID)
        .toArray();
      that.productDefaultExpandedKeys = Enumerable.from(that.savingCardProduct)
        .select((val) => val.PID)
        .toArray();
      that.projectDefaultCheckedKeys = Enumerable.from(that.savingCardProject)
        .select((val) => val.PID)
        .toArray();
      that.projectDefaultExpandedKeys = Enumerable.from(that.savingCardProject)
        .select((val) => val.PID)
        .toArray();
      that.generalDefaultCheckedKeys = Enumerable.from(that.savingCardGeneral)
        .select((val) => val.PID)
        .toArray();
      that.generalDefaultExpandedKeys = Enumerable.from(that.savingCardGeneral)
        .select((val) => val.PID)
        .toArray();
      that.timeDefaultCheckedKeys = Enumerable.from(that.savingCardTime)
        .select((val) => val.PID)
        .toArray();
      that.timeDefaultExpandedKeys = Enumerable.from(that.savingCardTime)
        .select((val) => val.PID)
        .toArray();
      that.packageDefaultCheckedKeys = Enumerable.from(that.savingCardPackage)
        .select((val) => val.PID)
        .toArray();
      that.packageDefaultExpandedKeys = Enumerable.from(that.savingCardPackage)
        .select((val) => val.PID)
        .toArray();

      that.dialogScope = true;
    },
    // 获取产品
    productData: function () {
      var that = this;
      that.loading = true;
      API.savingCardProduct()
        .then((res) => {
          if (res.StateCode == 200) {
            that.productList = res.Data;
            that.setRecursion(res.Data);
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.loading = false;
        });
    },
    // 获取项目
    projectData: function () {
      var that = this;
      that.loading = true;
      API.savingCardProject()
        .then((res) => {
          if (res.StateCode == 200) {
            that.projectList = res.Data;
            that.setRecursion(res.Data);
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.loading = false;
        });
    },
    // 获取通用次卡
    generalCardData: function () {
      var that = this;
      that.loading = true;
      API.savingCardGeneralCard()
        .then((res) => {
          if (res.StateCode == 200) {
            that.generalCardList = res.Data;
            that.setRecursion(res.Data);
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.loading = false;
        });
    },
    // 获取时效卡
    timeCardData: function () {
      var that = this;
      that.loading = true;
      API.savingCardTimeCard()
        .then((res) => {
          if (res.StateCode == 200) {
            that.timeCardList = res.Data;
            that.setRecursion(res.Data);
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.loading = false;
        });
    },
    // 获取套餐卡
    packageCardData: function () {
      var that = this;
      that.loading = true;
      API.savingCardPackageCard()
        .then((res) => {
          if (res.StateCode == 200) {
            that.packageCardList = res.Data;
            that.setRecursion(res.Data);
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.loading = false;
        });
    },
    // 递归
    setRecursion(data) {
      var that = this;
      for (let i = 0; i <= data.length - 1; i++) {
        if (data[i].IsGoods == 0) {
          data[i].PID = "0" + data[i].ID;
        } else {
          data[i].PID = "1" + data[i].ID;
        }
        if (data[i].Child) {
          that.setRecursion(data[i].Child);
        }
      }
    },
    // 产品选择状态变化
    changeProductData: function (val, val1) {
      var that = this;
      var defaultCheckedKeys = Enumerable.from(val1.checkedNodes)
        .select((val) => ({
          PID: val.PID,
          ID: val.ID,
          IsGoods: val.IsGoods,
          Name: val.Name,
          GoodsCategoryName: val.GoodsCategoryName,
          Price: val.Price,
          PriceType: 1,
          DiscountPrice: 10,
          cardType: "产品",
          isCardType: 1,
        }))
        .toArray();
      defaultCheckedKeys.forEach(function (item) {
        that.savingCardProduct.forEach(function (saving) {
          if (item.PID == saving.PID) {
            item.PriceType = saving.PriceType;
            item.DiscountPrice = saving.DiscountPrice;
          }
        });
      });
      that.savingCardProduct = defaultCheckedKeys;
    },
    // 项目选择状态变化
    changeProjectData: function (val, val1) {
      var that = this;
      var defaultCheckedKeys = Enumerable.from(val1.checkedNodes)
        .select((val) => ({
          PID: val.PID,
          ID: val.ID,
          IsGoods: val.IsGoods,
          Name: val.Name,
          GoodsCategoryName: val.GoodsCategoryName,
          Price: val.Price,
          PriceType: 1,
          DiscountPrice: 10,
          cardType: "项目",
          isCardType: 2,
        }))
        .toArray();
      defaultCheckedKeys.forEach(function (item) {
        that.savingCardProject.forEach(function (saving) {
          if (item.PID == saving.PID) {
            item.PriceType = saving.PriceType;
            item.DiscountPrice = saving.DiscountPrice;
          }
        });
      });
      that.savingCardProject = defaultCheckedKeys;
    },
    // 通用次卡选择状态变化
    changeGeneralData: function (val, val1) {
      var that = this;
      var defaultCheckedKeys = Enumerable.from(val1.checkedNodes)
        .select((val) => ({
          PID: val.PID,
          ID: val.ID,
          IsGoods: val.IsGoods,
          Name: val.Name,
          GoodsCategoryName: val.GoodsCategoryName,
          Price: val.Price,
          PriceType: 1,
          DiscountPrice: 10,
          cardType: "通用次卡",
          isCardType: 3,
        }))
        .toArray();
      defaultCheckedKeys.forEach(function (item) {
        that.savingCardGeneral.forEach(function (saving) {
          if (item.PID == saving.PID) {
            item.PriceType = saving.PriceType;
            item.DiscountPrice = saving.DiscountPrice;
          }
        });
      });
      that.savingCardGeneral = defaultCheckedKeys;
    },
    // 时效卡选择状态变化
    changeTimeData: function (val, val1) {
      var that = this;
      var defaultCheckedKeys = Enumerable.from(val1.checkedNodes)
        .select((val) => ({
          PID: val.PID,
          ID: val.ID,
          IsGoods: val.IsGoods,
          Name: val.Name,
          GoodsCategoryName: val.GoodsCategoryName,
          Price: val.Price,
          PriceType: 1,
          DiscountPrice: 10,
          cardType: "时效卡",
          isCardType: 4,
        }))
        .toArray();
      defaultCheckedKeys.forEach(function (item) {
        that.savingCardTime.forEach(function (saving) {
          if (item.PID == saving.PID) {
            item.PriceType = saving.PriceType;
            item.DiscountPrice = saving.DiscountPrice;
          }
        });
      });
      that.savingCardTime = defaultCheckedKeys;
    },
    // 套餐卡选择状态变化
    changePackageData: function (val, val1) {
      var that = this;
      var defaultCheckedKeys = Enumerable.from(val1.checkedNodes)
        .select((val) => ({
          PID: val.PID,
          ID: val.ID,
          IsGoods: val.IsGoods,
          Name: val.Name,
          GoodsCategoryName: val.GoodsCategoryName,
          Price: val.Price,
          PriceType: 1,
          DiscountPrice: 10,
          cardType: "套餐卡",
          isCardType: 5,
        }))
        .toArray();
      defaultCheckedKeys.forEach(function (item) {
        that.savingCardPackage.forEach(function (saving) {
          if (item.PID == saving.PID) {
            item.PriceType = saving.PriceType;
            item.DiscountPrice = saving.DiscountPrice;
          }
        });
      });
      that.savingCardPackage = defaultCheckedKeys;
    },
    // 删除选中适用项目
    removetProject: function (type, row) {
      var that = this;
      if (type == 1) {
        var savingCardProduct = Enumerable.from(that.savingCardProduct)
          .where(function (i) {
            return i.PID != row.PID;
          })
          .toArray();
        that.savingCardProduct = savingCardProduct;
        var savingCardProduct = Enumerable.from(that.savingCardProduct)
          .select((val) => val.PID)
          .toArray();
        that.$refs.treeProduct.setCheckedKeys(savingCardProduct);
      } else if (type == 2) {
        var savingCardProject = Enumerable.from(that.savingCardProject)
          .where(function (i) {
            return i.PID != row.PID;
          })
          .toArray();
        that.savingCardProject = savingCardProject;
        var savingCardProject = Enumerable.from(that.savingCardProject)
          .select((val) => val.PID)
          .toArray();
        that.$refs.treeProject.setCheckedKeys(savingCardProject);
      } else if (type == 3) {
        var savingCardGeneral = Enumerable.from(that.savingCardGeneral)
          .where(function (i) {
            return i.PID != row.PID;
          })
          .toArray();
        that.savingCardGeneral = savingCardGeneral;
        var savingCardGeneral = Enumerable.from(that.savingCardGeneral)
          .select((val) => val.PID)
          .toArray();
        that.$refs.treeGeneral.setCheckedKeys(savingCardGeneral);
      } else if (type == 4) {
        var savingCardTime = Enumerable.from(that.savingCardTime)
          .where(function (i) {
            return i.PID != row.PID;
          })
          .toArray();
        that.savingCardTime = savingCardTime;
        var savingCardTime = Enumerable.from(that.savingCardTime)
          .select((val) => val.PID)
          .toArray();
        that.$refs.treeTime.setCheckedKeys(savingCardTime);
      } else {
        var savingCardPackage = Enumerable.from(that.savingCardPackage)
          .where(function (i) {
            return i.PID != row.PID;
          })
          .toArray();
        that.savingCardPackage = savingCardPackage;
        var savingCardPackage = Enumerable.from(that.savingCardPackage)
          .select((val) => val.PID)
          .toArray();
        that.$refs.treePackage.setCheckedKeys(savingCardPackage);
      }
      that.collapseChange();
    },
    collapseChange: function () {
      var that = this;
      that.savingcardSelectAll = that.savingCardProduct.concat(
        that.savingCardProject,
        that.savingCardGeneral,
        that.savingCardTime,
        that.savingCardPackage
      );
    },
    // 确认选中适用项目
    addProjectSelect: function () {
      var that = this;
      var masterEntity = [],
        savingCardProduct = [],
        savingCardProject = [],
        savingCardGeneral = [],
        savingCardTime = [],
        savingCardPackage = [];
      if (that.savingCardProduct.length > 0) {
        savingCardProduct = Enumerable.from(that.savingCardProduct)
          .where(function (i) {
            return i.DiscountPrice == "";
          })
          .toArray();
      }
      if (that.savingCardProject.length > 0) {
        savingCardProject = Enumerable.from(that.savingCardProject)
          .where(function (i) {
            return i.DiscountPrice == "";
          })
          .toArray();
      }
      if (that.savingCardGeneral.length > 0) {
        savingCardGeneral = Enumerable.from(that.savingCardGeneral)
          .where(function (i) {
            return i.DiscountPrice == "";
          })
          .toArray();
      }
      if (that.savingCardTime.length > 0) {
        savingCardTime = Enumerable.from(that.savingCardTime)
          .where(function (i) {
            return i.DiscountPrice == "";
          })
          .toArray();
      }
      if (that.savingCardPackage.length > 0) {
        savingCardPackage = Enumerable.from(that.savingCardPackage)
          .where(function (i) {
            return i.DiscountPrice == "";
          })
          .toArray();
      }
      masterEntity = savingCardProduct.concat(
        savingCardProject,
        savingCardGeneral,
        savingCardTime,
        savingCardPackage
      );
      if (masterEntity.length > 0) {
        that.$message.error({
          message: "请输入折扣或价格",
          duration: 2000,
        });
        return false;
      }

      if (that.isLargess == 1) {
        that.goodsRangeProductList = that.savingCardProduct; //产品集合
        that.goodsRangeProjectList = that.savingCardProject; //项目集合
        that.goodsRangeGeneralCardList = that.savingCardGeneral; //通用次卡集合
        that.goodsRangeTimeCardList = that.savingCardTime; //时效卡集合
        that.goodsRangePackageCardList = that.savingCardPackage; //套餐卡集合
        that.goodsRangeList = that.goodsRangeProductList.concat(
          that.goodsRangeProjectList,
          that.goodsRangeGeneralCardList,
          that.goodsRangeTimeCardList,
          that.goodsRangePackageCardList
        );
      } else {
        that.goodsRangeLargessProductList = that.savingCardProduct; //产品集合
        that.goodsRangeLargessProjectList = that.savingCardProject; //项目集合
        that.goodsRangeLargessGeneralCardList = that.savingCardGeneral; //通用次卡集合
        that.goodsRangeLargessTimeCardList = that.savingCardTime; //时效卡集合
        that.goodsRangeLargessPackageCardList = that.savingCardPackage; //套餐卡集合
        that.goodsRangeLargessList = that.goodsRangeLargessProductList.concat(
          that.goodsRangeLargessProjectList,
          that.goodsRangeLargessGeneralCardList,
          that.goodsRangeLargessTimeCardList,
          that.goodsRangeLargessPackageCardList
        );
      }
      that.dialogScope = false;
    },
    // 提交
    addSubmit: function () {
      var that = this;
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          // 基本信息 验证
          if (that.ruleForm.IsUseRechargeRules) {
            // 阶梯价格 验证
            this.$refs.rechargeRulesRuleForm.validate((rechargeValid) => {
              if (rechargeValid) {
                that.SubmitNetWork();
              }
            });
          } else {
            that.SubmitNetWork();
          }
        }
      });
    },

    /**  提交数据请求  */
    SubmitNetWork() {
      let that = this;

      if (that.ruleForm.ValidType != 3 && that.ruleForm.ValidDay == "") {
        that.$message.error({
          message: "请输入有效期",
          duration: 2000,
        });
        return false;
      }
      that.modalLoading = true;
      that.ruleForm.SavingCardRechargeRules = that.ruleForm.IsUseRechargeRules
        ? that.ruleForm.SavingCardRechargeRules
        : [];
      let para = Object.assign({}, that.ruleForm);
      para.Memo = that.$refs["goodsDetail"].getMemo();
      para.ImageList = Enumerable.from(that.ruleForm.ImageList)
        .select((val) => ({ ImageUrl: val.url }))
        .toArray();
      var GoodsRangeProductCategory = Enumerable.from(
        that.goodsRangeProductList
      )
        .where(function (i) {
          return i.IsGoods == 0;
        })
        .select((val) => ({
          CategoryID: val.ID,
          IsLargess: false,
          Discount: val.DiscountPrice,
        }))
        .toArray();
      var GoodsRangeProduct = Enumerable.from(that.goodsRangeProductList)
        .where(function (i) {
          return i.IsGoods == 1;
        })
        .select((val) => ({
          ID: val.ID,
          IsLargess: false,
          PriceType: val.PriceType,
          DiscountPrice: val.DiscountPrice,
        }))
        .toArray();
      var GoodsRangeProjectCategory = Enumerable.from(
        that.goodsRangeProjectList
      )
        .where(function (i) {
          return i.IsGoods == 0;
        })
        .select((val) => ({
          CategoryID: val.ID,
          IsLargess: false,
          Discount: val.DiscountPrice,
          Name: val.Name,
        }))
        .toArray();
      var GoodsRangeProject = Enumerable.from(that.goodsRangeProjectList)
        .where(function (i) {
          return i.IsGoods == 1;
        })
        .select((val) => ({
          ID: val.ID,
          IsLargess: false,
          PriceType: val.PriceType,
          DiscountPrice: val.DiscountPrice,
        }))
        .toArray();
      var GoodsRangeGeneralCategory = Enumerable.from(
        that.goodsRangeGeneralCardList
      )
        .where(function (i) {
          return i.IsGoods == 0;
        })
        .select((val) => ({
          CategoryID: val.ID,
          IsLargess: false,
          Discount: val.DiscountPrice,
        }))
        .toArray();
      var GoodsRangeGeneral = Enumerable.from(that.goodsRangeGeneralCardList)
        .where(function (i) {
          return i.IsGoods == 1;
        })
        .select((val) => ({
          ID: val.ID,
          IsLargess: false,
          PriceType: val.PriceType,
          DiscountPrice: val.DiscountPrice,
        }))
        .toArray();
      var GoodsRangeTimeCategory = Enumerable.from(that.goodsRangeTimeCardList)
        .where(function (i) {
          return i.IsGoods == 0;
        })
        .select((val) => ({
          CategoryID: val.ID,
          IsLargess: false,
          Discount: val.DiscountPrice,
        }))
        .toArray();
      var GoodsRangeTime = Enumerable.from(that.goodsRangeTimeCardList)
        .where(function (i) {
          return i.IsGoods == 1;
        })
        .select((val) => ({
          ID: val.ID,
          IsLargess: false,
          PriceType: val.PriceType,
          DiscountPrice: val.DiscountPrice,
        }))
        .toArray();
      var GoodsRangePackageCategory = Enumerable.from(
        that.goodsRangePackageCardList
      )
        .where(function (i) {
          return i.IsGoods == 0;
        })
        .select((val) => ({
          CategoryID: val.ID,
          IsLargess: false,
          Discount: val.DiscountPrice,
        }))
        .toArray();
      var GoodsRangePackage = Enumerable.from(that.goodsRangePackageCardList)
        .where(function (i) {
          return i.IsGoods == 1;
        })
        .select((val) => ({
          ID: val.ID,
          IsLargess: false,
          PriceType: val.PriceType,
          DiscountPrice: val.DiscountPrice,
        }))
        .toArray();

      var GoodsRangeLargessProductCategory = Enumerable.from(
        that.goodsRangeLargessProductList
      )
        .where(function (i) {
          return i.IsGoods == 0;
        })
        .select((val) => ({
          CategoryID: val.ID,
          IsLargess: false,
          Discount: val.DiscountPrice,
        }))
        .toArray();
      var GoodsRangeLargessProduct = Enumerable.from(
        that.goodsRangeLargessProductList
      )
        .where(function (i) {
          return i.IsGoods == 1;
        })
        .select((val) => ({
          ID: val.ID,
          IsLargess: false,
          PriceType: val.PriceType,
          DiscountPrice: val.DiscountPrice,
        }))
        .toArray();
      var GoodsRangeLargessProjectCategory = Enumerable.from(
        that.goodsRangeLargessProjectList
      )
        .where(function (i) {
          return i.IsGoods == 0;
        })
        .select((val) => ({
          CategoryID: val.ID,
          IsLargess: false,
          Discount: val.DiscountPrice,
          Name: val.Name,
        }))
        .toArray();
      var GoodsRangeLargessProject = Enumerable.from(
        that.goodsRangeLargessProjectList
      )
        .where(function (i) {
          return i.IsGoods == 1;
        })
        .select((val) => ({
          ID: val.ID,
          IsLargess: false,
          PriceType: val.PriceType,
          DiscountPrice: val.DiscountPrice,
        }))
        .toArray();
      var GoodsRangeLargessGeneralCategory = Enumerable.from(
        that.goodsRangeLargessGeneralCardList
      )
        .where(function (i) {
          return i.IsGoods == 0;
        })
        .select((val) => ({
          CategoryID: val.ID,
          IsLargess: false,
          Discount: val.DiscountPrice,
        }))
        .toArray();
      var GoodsRangeLargessGeneral = Enumerable.from(
        that.goodsRangeLargessGeneralCardList
      )
        .where(function (i) {
          return i.IsGoods == 1;
        })
        .select((val) => ({
          ID: val.ID,
          IsLargess: false,
          PriceType: val.PriceType,
          DiscountPrice: val.DiscountPrice,
        }))
        .toArray();
      var GoodsRangeLargessTimeCategory = Enumerable.from(
        that.goodsRangeLargessTimeCardList
      )
        .where(function (i) {
          return i.IsGoods == 0;
        })
        .select((val) => ({
          CategoryID: val.ID,
          IsLargess: false,
          Discount: val.DiscountPrice,
        }))
        .toArray();
      var GoodsRangeLargessTime = Enumerable.from(
        that.goodsRangeLargessTimeCardList
      )
        .where(function (i) {
          return i.IsGoods == 1;
        })
        .select((val) => ({
          ID: val.ID,
          IsLargess: false,
          PriceType: val.PriceType,
          DiscountPrice: val.DiscountPrice,
        }))
        .toArray();
      var GoodsRangeLargessPackageCategory = Enumerable.from(
        that.goodsRangeLargessPackageCardList
      )
        .where(function (i) {
          return i.IsGoods == 0;
        })
        .select((val) => ({
          CategoryID: val.ID,
          IsLargess: false,
          Discount: val.DiscountPrice,
        }))
        .toArray();
      var GoodsRangeLargessPackage = Enumerable.from(
        that.goodsRangeLargessPackageCardList
      )
        .where(function (i) {
          return i.IsGoods == 1;
        })
        .select((val) => ({
          ID: val.ID,
          IsLargess: false,
          PriceType: val.PriceType,
          DiscountPrice: val.DiscountPrice,
        }))
        .toArray();
      // var SavingCardRechargeRules = Enumerable.from(that.ruleForm.SavingCardRechargeRules).select((val)=>({
      //   Price:val.Price,
      //   LargessPrice:LargessPrice
      // })).toArray();

      // para.SavingCardRechargeRules = SavingCardRechargeRules;

      para.GoodsRange.Product = GoodsRangeProduct;
      para.GoodsRange.ProductCategory = GoodsRangeProductCategory;
      para.GoodsRange.Project = GoodsRangeProject;
      para.GoodsRange.ProjectCategory = GoodsRangeProjectCategory;
      para.GoodsRange.GeneralCard = GoodsRangeGeneral;
      para.GoodsRange.GeneralCardCategory = GoodsRangeGeneralCategory;
      para.GoodsRange.TimeCard = GoodsRangeTime;
      para.GoodsRange.TimeCardCategory = GoodsRangeTimeCategory;
      para.GoodsRange.PackageCard = GoodsRangePackage;
      para.GoodsRange.PackageCardCategory = GoodsRangePackageCategory;

      para.GoodsRangeLargess.Product = GoodsRangeLargessProduct;
      para.GoodsRangeLargess.ProductCategory = GoodsRangeLargessProductCategory;
      para.GoodsRangeLargess.Project = GoodsRangeLargessProject;
      para.GoodsRangeLargess.ProjectCategory = GoodsRangeLargessProjectCategory;
      para.GoodsRangeLargess.GeneralCard = GoodsRangeLargessGeneral;
      para.GoodsRangeLargess.GeneralCardCategory =
        GoodsRangeLargessGeneralCategory;
      para.GoodsRangeLargess.TimeCard = GoodsRangeLargessTime;
      para.GoodsRangeLargess.TimeCardCategory = GoodsRangeLargessTimeCategory;
      para.GoodsRangeLargess.PackageCard = GoodsRangeLargessPackage;
      para.GoodsRangeLargess.PackageCardCategory =
        GoodsRangeLargessPackageCategory;

      para.SaleStartDate = para.saleDate[0];
      para.SaleEndingDate = para.saleDate[1];
      para.SavingCardSaleEntity = that.$refs.treeSale.getCheckedKeys();
      para.SavingCardConsumeEntity = that.$refs.treeConsume.getCheckedKeys();
      delete para.saleDate;

      if (that.isAdd) {
        API.createSavingCard(para)
          .then(function (res) {
            if (res.StateCode === 200) {
              that.$message.success({
                message: "新增成功",
                duration: 2000,
              });
              that.search();
              that.$refs["ruleForm"].resetFields();
              that.dialogVisible = false;
            } else {
              that.$message.error({
                message: res.Message,
                duration: 2000,
              });
            }
          })
          .finally(function () {
            that.modalLoading = false;
          });
      } else {
        API.updateSavingCard(para)
          .then(function (res) {
            if (res.StateCode === 200) {
              that.$message.success({
                message: "编辑成功",
                duration: 2000,
              });
              that.search();
              that.$refs["ruleForm"].resetFields();
              that.dialogVisible = false;
            } else {
              that.$message.error({
                message: res.Message,
                duration: 2000,
              });
            }
          })
          .finally(function () {
            that.modalLoading = false;
          });
      }
    },

    // 折扣/价格
    priceTypeChange: function (row) {
      if (row.PriceType == 1) {
        if (row.DiscountPrice > 10) {
          row.DiscountPrice = 10;
        }
      } else {
        if (row.DiscountPrice > row.Price) {
          row.DiscountPrice = row.Price;
        }
      }
    },

    // 批量设置
    dropdownClick: function () {},

    /**  是否使用阶梯价格  */
    changeIsUseRechargeRules(value) {
      let that = this;
      if (value) {
        that.ruleForm.Price = "0";
        that.ruleForm.LargessPrice = "0";
        if (that.ruleForm.SavingCardRechargeRules.length == 0) {
          that.ruleForm.SavingCardRechargeRules.push({
            Price: "",
            LargessPrice: "",
          });
        }
      } else {
        that.ruleForm.Price = "";
        that.ruleForm.LargessPrice = "";
      }
    },
    /**  添加阶梯价格规则  */
    addTieredPriceClick() {
      let that = this;
      that.ruleForm.SavingCardRechargeRules.push({
        Price: "",
        LargessPrice: "",
      });
    },
    /**  删除阶梯价格规则  */
    removeTieredPriceClick(index) {
      let that = this;
      that.ruleForm.SavingCardRechargeRules.splice(index, 1);
    },
    /**    */
    Memo_change(val) {
      let that = this;
      that.ruleForm.Memo = val;
    },
    /**    */
    ImageListChange(val) {
      let that = this;
      that.ruleForm.ImageList = val;
    },
    /**  请求详情  */
    getSavingCardDetails(ID) {
      let that = this;
      let param = { ID: ID };
      API.getSavingCardDetails(param)
        .then((res) => {
          if (res.StateCode == 200) {
            that.$set(that.ruleForm, "ImageList", []);
            that.$set(that.ruleForm, "Memo", "");
            that.$set(that.ruleForm, "ApplyNum", "");

            res.Data.ImageUrlList.forEach((item) => {
              utils.getCanvasBase64(item.ImageUrl).then((val) => {
                that.ruleForm.ImageList.push({ url: val });
              });
            });
            that.ruleForm.Memo = res.Data.Memo;
            that.ruleForm.ApplyNum = res.Data.ApplyNum;
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {});
    },
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.isSavingCardEdit = permission.permission(
        to.meta.Permission,
        "iBeauty-Goods-SavingCard-Update"
      );
      vm.isSavingCardNew = permission.permission(
        to.meta.Permission,
        "iBeauty-Goods-SavingCard-Create"
      );
    });
  },
  mounted() {
    var that = this;
    that.isSavingCardEdit = permission.permission(
      that.$route.meta.Permission,
      "iBeauty-Goods-SavingCard-Update"
    );
    that.isSavingCardNew = permission.permission(
      that.$route.meta.Permission,
      "iBeauty-Goods-SavingCard-Create"
    );
    that.categoryData();
    that.handleSearch();
    that.entityData();
    that.productData();
    that.projectData();
    that.generalCardData();
    that.timeCardData();
    that.packageCardData();
  },
};
</script>

<style lang="scss">
.SavingCard {
  .radio_input {
    width: 150px;
    margin-left: 15px;
  }

  .el-table {
    .caret-wrapper {
      height: 23px;
      .ascending {
        top: 0px;
      }
      .descending {
        bottom: 1px;
      }
    }
  }

  .el-dialog__body {
    .el_input_select {
      padding-top: 1px;
      .el-input-group__append {
        width: 54px;
        .el-input__inner {
          padding: 8px;
        }
      }
    }
  }

  .el-collapse-item__content {
    padding: 0px;
  }
  .savingShow {
    .el-form-item__label {
      color: #666;
    }
  }
  .el-scrollbar_height {
    height: 60vh;
    .el-scrollbar__wrap {
      overflow-x: hidden;
    }
  }
  .el-scrollbar_height_range {
    height: 35vh;
    .el-scrollbar__wrap {
      overflow-x: hidden;
    }
  }
}
</style>
